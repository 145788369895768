import { Product } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { PRODUCT_FIELDS } from "@/graphql/product/product";
import { useQuery } from "@vue/apollo-composable";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { computed } from "vue";

type ProductDiscountData = {
  productDiscount?: Product;
};
const QUERY_PRODUCT_DISCOUNT = gql`
    query ProductDiscount{
        productDiscount {
            ${PRODUCT_FIELDS}
            stocks{${STOCK_FIELDS}}
        }
    }
`;
export const useProductDiscount = ({
  allowDiscount,
}: {
  allowDiscount: boolean;
}) => {
  const { loading, result, onResult } = useQuery<ProductDiscountData>(
    QUERY_PRODUCT_DISCOUNT,
    {},
    { fetchPolicy: allowDiscount ? "network-only" : "cache-only" }
  );

  onResult(({ data }) => {
    console.log(data);
  });

  return {
    loading,
    product: computed(() => result.value?.productDiscount),
  };
};


import { useProductDiscount } from "@/graphql/product/product.discount";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PosDiscountProduct",
  emits: ["addDiscount"],
  props: {
    allowDiscountLines: Boolean,
  },
  setup(props) {
    const { loading, product } = useProductDiscount({
      allowDiscount: props.allowDiscountLines,
    });
    return {
      loading,
      product,
    };
  },
});

import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_Button, {
    loading: _ctx.loading,
    disabled: !_ctx.product || _ctx.loading,
    class: "p-button-sm p-ml-2 p-button-help",
    icon: "pi pi-money-bill",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addDiscount', [$event, _ctx.product])))
  }, null, 8, ["loading", "disabled"])), [
    [
      _directive_tooltip,
      _ctx.$t('terminal.addDiscountLine'),
      void 0,
      { bottom: true }
    ]
  ])
}